import * as React from "react"
import { graphql } from "gatsby"

// All the gatsby-plugin-image goodness
import { StaticImage, getImage } from "gatsby-plugin-image"

// The bridge for Gatsby Background Image in V3
import { BgImage } from 'gbimage-bridge'

// React Bootstrap
import { Container, Row, Col, Form } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

import twoStrokes from "../images/knife-and-fork.svg"
import igGradient from "../images/Instagram_Glyph_Gradient_RGB.svg"


const IndexPage = ({data}) => (
  <Layout>
    <Seo title="Catering Services" />
    <BgImage 
      image={getImage(data.indexImage)} 
      className="masthead my-4"
    >
      <div className="color-overlay d-flex flex-column justify-content-center align-items-center">
        <h1 className="d-none">Catering Services</h1>
      </div>
    </BgImage>
    <Container className="singleCol">
      <p className="lead">
        Finding the perfect catering service is never an easy task. P.S Catering can make your life easier and stress-free! Our menus are customizable and easily tailored to fit your gastronomical needs and desired taste. We pride ourselves on quality, presentation and inspiring flavors. We handle everything from the initial setup, to the cooking, to the clean-up. Our private catering service makes no event too big or too small with attention to detail. Have your next event catered by someone who really loves what they do. Call P.S Catering today for more information on our catering services for availability and affordable pricing: – private parties, weddings, meal prep menus…
      </p>

      <p className="lead">
        <em>When you use P.S Catering, you truly get The Best of Everything!</em>
      </p>
      </Container>
      <Container>
          <p>
          <StaticImage 
              src="../images/3-foods.jpg"
              alt="Michael"
              placeholder="blurred"
              className="3 kinds of foods"
            />
          </p>
          </Container>

          <Container className="singleCol">

      <h2>Catering Services for All Occasions</h2>
      <p>
        With P.S Catering, you can enjoy mouthwatering home-cooked meals, appetizers to "wow" your guests, delectable entrées, amazing desserts and even delicate finger foods. We cater dinner parties, birthday bashes, special events, bridal luncheons and even provide daily meal prep menus.
      </p>
      <p>
        <em>Give us a call and learn more about how we can cater to your weekly needs or that special event!</em>
      </p>

      {/* <h2>Personal Chef Experience</h2>
      <p>
        P.S Catering is not your average catering service. From start to finish, each event is carefully planned and tailored to suit your needs. With years of personal chef experience, we will create a personalized menu that will bring your event to life and keep guests coming back for more!
      </p>
      <p>
        When we say personalized, we mean it! For example, if you want an ice cream bar at your wedding but don't want any dairy products at the event, that's no problem! We will create a completely vegan dessert bar that fits the theme of your reception and leaves guests feeling satisfied without upsetting anyone's dietary restrictions.
      </p> */}

      <h2>Personal Professional Chef Experience</h2>
      
      <p>
        With many years of culinary experience, our team can create a personalized menu that will cater to your every need and expectation. It will keep your guests coming back for more! We have a trained personal chef who can ensure that every aspect of your event goes as planned. We will create a customized menu that meets your dietary needs staying within your budget, without sacrificing quality and variety– so there are no unexpected surprises on your big day!
      </p>

      <p>
        <em>A catering service that goes above and beyond. </em>
      </p>

      <p className="float-img float-start me-3 text-center">
            <StaticImage 
              src="../images/two-chefs.jpg"
              alt="Peter & Sacha"
              placeholder="blurred"
              className="float-img"
            />
          </p>

      <h2>
        Daily Meal Prep Menus
      </h2>
      <p>
        Trying to find meals you can make for lunch or dinner is an exhausting process. With so many options and hardly any time, it feels like an impossible task! Ordering from us affords you free time for yourself, less grocery shopping, less cooking time and even wastage. Imagine walking into your kitchen every day knowing you have a healthy lunch or dinner in the fridge – just a few steps away! Imagine having a menu laid out for you with all of your favorite dishes, Daily Meal Prep Menus, and Weekly Menus delivered right to your door. 
      </p>
      <p>
        <em>No more decision burnout.</em>
      </p>

      <h2>Eating Healthy Isn't Hard</h2>
      <p>
        Many people do not understand the concept of eating healthy. Think about it. How can you have that perfectly balanced meal everyday? For many, cooking healthy meals means spending a lot of time planning and endless hours prepping in the kitchen, the time they don't have to spare during their hectic day. We understand the need.
      </p>
      <p>
        <em>Leave it to the professionals. That's where we come in.</em>
      </p>

      <h2>
        You Don't Have To Be A Chef To Eat A Great Meal
      </h2>
      <p>
        Many times you end up buying the same ingredients and even preparing the same meals. What makes us unique? We offer variety that will expand your taste buds. With P.S Catering, you can choose from a variety of different menus every week or customize your own. You never have to worry about cooking again because we will handle all the work for you. All you need to do is pick up the phone or email P.S Catering, and they'll bring the food right to your door!
      </p>
      <p>
        <em>It takes the hassle out of dinner!</em>
      </p>

      <p className="text-center">
        <img src={twoStrokes} className="two brush strokes" alt="brush strokes" width="100" />
      </p>

      <h2 className="text-center">About Us</h2>
        <p className="float-img float-none float-md-start me-md-3 text-center">
            <StaticImage 
              src="../images/sacha-and-michael.jpg"
              alt="Peter & Sacha"
              placeholder="blurred"
              className="float-img"
            />
          </p>
          
          <p>P.S catering is a family-owned food catering business located in Savannah, Georgia, USA. Our belief in eating healthy and supporting our local food markets has always been our primary focus.  Our main goal is to deliver quality meals. Whether you're looking for healthy grab-and-go meals or delicious plated feasts for your Bridal Luncheons, Baby showers, Intimate weddings, or Engagement parties, we have a menu tailored to your taste preferences and dietary needs. With an ever-growing list of available options, it isn't easy to ensure you're hiring a catering service that will provide excellent food and work well with your other vendors.</p>
          
          <p>
            <em>Overall we'll help you create a spectacular event experience.</em>
          </p>

          <h2>
            Why Choose P.S Catering?
          </h2>
          <p>
            We are a husband and wife team with an extensive culinary background. Sacha, the S in P.S Catering, is the daughter of Restaurateurs who were pioneers in the Savannah fine dining food scene. She also studied hotel and restaurant management in Europe. Peter, the P in P.S Catering, is a graduate from Johnson & Wales University and comes from a culinary background. His family owned a catering business in New York City for many years. Together they bring a wealth of experience and knowledge of what good food is and should be.
          </p>
          <p>
            Here at P.S Catering, we're not interested in providing just any old service. We're interested in making life easier for you. Plus, P.S Catering delivers and does catering events so that no matter where you are, we can be there too.
          </p>
          <p>
          When you use P.S Catering for your event, just sit back and enjoy the party without stressing about anything else!
          </p>
          </Container>

          <Container>
          <p>
          <StaticImage 
              src="../images/3-foods-part-2.jpg"
              alt="Michael"
              placeholder="blurred"
              className="3 kinds of foods"
            />
          </p>
          </Container>

          <Container className="singleCol">

          

          <p className="text-center">
            <img src={twoStrokes} className="two brush strokes" alt="brush strokes" width="100" />
          </p>
          <h2 className="text-center">Contact Us</h2>

          <Row>
        <Col lg={7}>
        <Form 
                        name="contact"
                        method="post"
                        // action="/contact-success/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit="submit"
                    >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" />
                    </label>
                    </p>
                     


                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label for="firstName">First Name</Form.Label>
                                    <Form.Control 
                                        type="text" name="first-name" id="firstName"
                                     />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                            <Form.Group>
                                    <Form.Label for="lastName">Last Name</Form.Label>
                                    <Form.Control 
                                       type="text" name="last-name"
                                       id="lastName"
                                     />
                            </Form.Group>
                            </Col>
                        </Row>


                            
                        <Form.Group>
                            <Form.Label for="email">Email address</Form.Label>
                            <Form.Control 
                                required
                                type="email" 
                                name="email" 
                                id="email"
                            />
                        </Form.Group>
                        
                        <Form.Group>
                            <Form.Label for="help">Message</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                name="message"
                                rows="3"
                                id="help"
                             />
                        </Form.Group>
                        <button type="submit" className="btn btn-outline-dark">Submit</button>
                        </Form>
          </Col>
          <Col lg={5} className="mt-2">
            <p>Get weekly meal plans delivered right to your door! Contact P.S Catering today to try it out!</p>
            <p><strong>Office Location:</strong> Savannah, Georgia</p>
            <p>
              <a href="https://www.instagram.com/p.s_catering/">
                Find us on Instagram <br />
                <img src={igGradient} alt="instagram" width="50" />
              </a>
            </p>
        </Col>
        </Row>

    </Container>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Catering Services, Daily Meal Prep & More" />

export default IndexPage

export const query = graphql`
  query {
    indexImage: file(relativePath: {eq: "grilled-pineapple.jpg"}) {
      id
      base
      relativePath
      childImageSharp {
        gatsbyImageData(
          width: 2500
          webpOptions: {quality: 30}
          placeholder: BLURRED
          blurredOptions: {width: 30}
        )
      }
    }
  }
`